:root {
  --main-bg-color: 240;
}

@font-face {
  font-family: mosk;
  src: url(resources/Mosk_Typeface/Mosk\ Bold\ 700.ttf);
  font-weight: bold;
}

@font-face {
  font-family: mosk;
  src: url(resources/Mosk_Typeface/Mosk\ Normal\ 400.ttf);
  font-weight: normal;
}

html {
  background-color: rgb(
    var(--main-bg-color),
    var(--main-bg-color),
    var(--main-bg-color)
  );
  /* margin: 0; */
}
/* html {
  
} */
/* .App {
  width: inherit;
  margin: 0 auto;
} */
