.edu-container {
  display: flex;
  flex-direction: row;
  margin: 0 10%;
  align-items: center;
}

.bear {
  width: 40%;
  margin-right: auto;
  min-width: 100px;
}

.edu-text {
  width: 60%;
  /* padding: 4rem; */
  /* padding: 1rem; */
  /* padding-left: 10%; */
}

@media (max-width: 768px) {
  .edu-text {
    width: 100%;
  }
  .bear {
    width: 100%;
    margin: 0 auto;
  }
  .edu-container {
    flex-direction: column;
  }
  .heading-title {
    text-align: center;
  }
  .edu-text > p {
    text-align: center;
  }
}
/* .title {
  font-weight: bolder;
  font-size: 4rem;
  font-family: mosk;
  background-color: black;

  background-image: linear-gradient(60deg, #e21143, #ffb03a);
  background-clip: text;
  color: transparent;
} */

/* fallback for IE */
.heading-title {
  color: black;
  font-weight: bolder;
  font-size: 4rem;
  font-family: mosk;
}
@supports (background-clip: text) {
  .heading-title {
    background-image: linear-gradient(
      60deg,
      rgb(99, 197, 202),
      rgb(40, 20, 91)
    );
    background-clip: text;
    color: transparent;
  }
}

@media (max-width: 300px) {
  .heading-title {
    font-size: 15vw;
  }
}
