.navbarContainer {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: saturate(100%) blur(20px);
  border-radius: 3rem;
  width: 70%;
  /* margin: 1rem auto 0; */
  padding: 0 1rem 0;

  position: fixed;
  left: 50%;
  top: 1rem;
  transform: translateX(-50%);
  z-index: 5;
}

.navList {
  float: right;
}
.navList:nth-of-type(2) {
  margin-left: auto;
}
.navList:first-of-type() {
  margin-left: 0.8rem;
}
.navList a {
  display: block;
  color: rgb(21, 21, 21);
  text-align: center;
  padding: 0.8rem 0.3rem;
  text-decoration: none;
}
.navMobile {
  display: flex;
  align-items: center;
}
/* Change the link color to #111 (black) on hover */
.navList a:hover {
  font-weight: bold;
}
@media (min-width: 601px) {
  .navbar-toggler {
    display: none;
  }
}
@media (max-width: 600px) {
  #navHideLi {
    display: none;
  }
}
/* @media (max-width: 950px) {
  .navList {
    font-size: 0.9rem;
  }
} */

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler {
  /* float: right; */
  margin-left: auto;
  text-align: center;
  margin-right: 1rem;
}
