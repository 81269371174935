.exp-container {
  display: flex;
  flex-direction: row;
  margin: 0 10%;
  align-items: center;
  padding: 1rem;
}

@media (max-width: 768px) {
  .exp-container {
    margin: 0 2%;
  }
}

.exp-text {
  width: 100%;
  /* padding: 4rem; */
  padding: 1rem;
  /* padding-left: 10%; */
  /* margin-left: auto; */
  // width: calc(100% - 2rem);
}

/* fallback for IE */
.heading-title {
  color: black;
  font-weight: bolder;
  font-size: 4rem;
  font-family: mosk;
}
/* gradient text for modern browsers */
@supports (background-clip: text) {
  .heading-title {
    background-image: linear-gradient(
      60deg,
      rgb(99, 197, 202),
      rgb(40, 20, 91)
    );
    background-clip: text;
    color: transparent;
    /* background-size: 50%; */
  }
}

// html,
// body {
//   background-color: #f0f0f0;
//   height: 100%;
//   font-family: "Open Sans", sans-serif;
//   box-sizing: border-box;
// }

.cd-container {
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;
  background: #f0f0f0;
  padding: 0 10%;
  border-radius: 2px;
}
.cd-container::after {
  content: "";
  display: table;
  clear: both;
}

/* -------------------------------- 

Main components 

-------------------------------- */

#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#cd-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25px;
  height: 100%;
  width: 4px;
  background: rgb(99, 197, 202);
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 8px;
  left: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #41828a, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img {
  background: rgb(99, 197, 202);
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 30px;
    height: 30px;
    left: 50%;
    margin-left: -15px;
    margin-top: 15px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  // margin-right: 30px;
  background: #ffffff;
  border-radius: 2px;
  padding: 1em;
  .timeline-content-info {
    background: #f0f0f0;
    padding: 5px 10px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    box-shadow: inset 0 2px 0 rgba(255, 255, 255, 0.08);
    border-radius: 2px;
    i {
      margin-right: 5px;
    }
    .timeline-content-info-title {
      width: calc(55% - 2px);
      display: inline-block;
    }
    .timeline-content-info-date {
      width: calc(45% - 2px);
      display: inline-block;

      text-align: end;
    }
    @media (max-width: 500px) {
      .timeline-content-info-title,
      .timeline-content-info-date {
        display: block;
        width: 100%;
      }
    }
  }
  .content-skills {
    font-size: 12px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      background: #41828a;
      border-radius: 2px;
      display: inline-block;
      padding: 2px 10px;
      color: rgba(255, 255, 255, 0.7);
      margin: 3px 2px;
      text-align: center;
      flex-grow: 1;
      font-weight: bold;
    }
  }
}
.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-content h2 {
  color: rgba(0, 0, 0, 0.9);
  margin-top: 0;
  margin-bottom: 5px;
}
.cd-timeline-content p,
.cd-timeline-content .cd-date {
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  font-size: 0.8125rem;
}
.cd-timeline-content .cd-date {
  display: inline-block;
}
.cd-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}

.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #333c42;
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .cd-timeline-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-timeline-content .cd-read-more,
  .cd-timeline-content .cd-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    color: white;
    margin-left: 0;
    padding: 1.6em;
    width: 40%;
    margin: 0 5%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
}

//  {

// }
.job-title {
  text-decoration: none;
  color: black;
  &:hover {
    color: darkblue;
    cursor: pointer;
  }
}
