.proj-container {
  display: flex;
  flex-direction: row;
  margin: 0 10% 3rem;
  align-items: center;
}

.project-card {
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 1.5rem;
  margin: 0;
  border-top: 3px solid var(--blue);
  & > h3 {
    color: black;
    font-weight: bold;
    margin: 0 0 1rem;
  }
}
.projects-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}
@media (min-width: 600px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .project-card {
    margin: 20px;
  }
}
@media (min-width: 1100px) {
  .projects-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
