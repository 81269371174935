.header-text {
  font-weight: bolder;
  font-size: 4rem;
  font-family: mosk;
  margin-left: 2rem;
}
.header-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5%;
}
.header-pic {
  width: 40%;
  padding-left: 10%;
  height: 100vh;
  color: white;
  //   flex-grow: 0;
  //   flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profpic {
  width: calc(100% - 1rem);
  object-fit: contain;
  border-radius: 50%;
  background: #e0e0e0;
  box-shadow: 25px 25px 49px #bebebe, -25px -25px 49px #ffffff;
  border: 0.5rem solid white;
}
.subheading {
  font-size: 1rem;
  font-weight: normal;
  margin: 1rem 0;
}

@media (max-width: 600px) {
  .header-container {
    flex-direction: column;
    // margin: 0 auto;
  }
  .header-pic {
    width: 100%;
    // padding: 0;
    padding: 20%;
    text-align: center;
    flex-direction: column;
    height: initial;
  }
  .profpic {
    // width: 50%;
  }
  .header-text {
    margin: 0;
    text-align: center;
  }
}
// @media (min-width: 1000px) {
//   .profpic {
//     max-width: 200px;
//   }
// }
